:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ov-hidden{overflow:hidden;}
:root ._f-1{flex:1;}
:root ._fd-row{flex-direction:row;}
:root ._h-auto{height:auto;}
:root ._fg-0{flex-grow:0;}
:root ._pr-1481558214{padding-right:var(--space-7);}