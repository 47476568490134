:root ._pos-relative{position:relative;}
:root ._ai-center{align-items:center;}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._pt-1481558214{padding-top:var(--space-7);}
:root ._pb-1481558214{padding-bottom:var(--space-7);}
:root ._pr-1481558152{padding-right:var(--space-9);}
:root ._pl-1481558152{padding-left:var(--space-9);}
:root ._w-auto{width:auto;}
:root ._bg-43812542{background-color:var(--color-73);}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-137133889{border-top-color:var(--color-3);}
:root ._brc-137133889{border-right-color:var(--color-3);}
:root ._bbc-137133889{border-bottom-color:var(--color-3);}
:root ._blc-137133889{border-left-color:var(--color-3);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}