:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._pt-1316330114{padding-top:var(--space-10);}
:root ._pr-1316330114{padding-right:var(--space-10);}
:root ._pb-1316330114{padding-bottom:var(--space-10);}
:root ._pl-1316330114{padding-left:var(--space-10);}
:root ._gap-1316330114{gap:var(--space-10);}
@media screen and (min-width: 769px) { :root:root:root ._ai-_gtSm_stretch{align-items:stretch;} }
@media screen and (min-width: 769px) { :root:root:root ._pt-_gtSm_1316330331{padding-top:var(--space-17);} }
@media screen and (min-width: 769px) { :root:root:root ._pr-_gtSm_1316330331{padding-right:var(--space-17);} }
@media screen and (min-width: 769px) { :root:root:root ._pb-_gtSm_1316330331{padding-bottom:var(--space-17);} }
@media screen and (min-width: 769px) { :root:root:root ._pl-_gtSm_1316330331{padding-left:var(--space-17);} }
@media screen and (min-width: 769px) { :root:root:root ._fb-_gtSm_5037{flex-basis:50%;} }
:root ._ai-stretch{align-items:stretch;}
:root ._gap-1481558276{gap:var(--space-5);}
:root ._col-675002279{color:var(--color);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-600{font-weight:600;}
:root ._ls-905096869{letter-spacing:var(--f-21-20);}
:root ._fos-1477258436{font-size:var(--f-si-20);}
:root ._lh-1677662493{line-height:var(--f-li-20);}
:root ._ta-center{text-align:center;}
@media screen and (min-width: 769px) { :root:root:root ._ta-_gtSm_left{text-align:left;} }
@media screen and (min-width: 769px) { :root:root:root ._ff-_gtSm_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 769px) { :root:root:root ._fow-_gtSm_600{font-weight:600;} }
@media screen and (min-width: 769px) { :root:root:root ._ls-_gtSm_905096900{letter-spacing:var(--f-21-21);} }
@media screen and (min-width: 769px) { :root:root:root ._fos-_gtSm_1477258405{font-size:var(--f-si-21);} }
@media screen and (min-width: 769px) { :root:root:root ._lh-_gtSm_1677662462{line-height:var(--f-li-21);} }
:root ._fow-233016109{font-weight:var(--f-we-3);}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}
:root ._col-43815425{color:var(--color-43);}
:root ._fg-1{flex-grow:1;}
:root ._h-300px{height:300px;}
:root ._w-10037{width:100%;}
:root ._ov-hidden{overflow:hidden;}
@media screen and (min-width: 577px) { :root:root:root ._h-_gtXs_350px{height:350px;} }
@media screen and (min-width: 769px) { :root:root:root:root ._h-_gtSm_auto{height:auto;} }