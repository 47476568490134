:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._col-675002279{color:var(--color);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-600{font-weight:600;}
:root ._ls-905096869{letter-spacing:var(--f-21-20);}
:root ._fos-1477258436{font-size:var(--f-si-20);}
:root ._lh-1677662493{line-height:var(--f-li-20);}
:root ._ta-center{text-align:center;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-row{flex-direction:row;}
:root ._gap-1481558152{gap:var(--space-9);}